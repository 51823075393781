import React from "react";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import SecondFooter from "./SecondFooter";
import StickyFooter from "./StickyFooter";
import ThirdFooter from "./ThirdFooter";

function FooterWrapper() {
  const sitedata = useSelector((store) => store.global.loginData);
  return (
    <>
      {sitedata?.theme_footer === "version_1" ? (
        <Footer />
      ) : sitedata?.theme_footer === "version_2" ? (
        <SecondFooter />
      ) : sitedata?.theme_footer === "version_3" ? (
        <div style={{ marginBottom: "418px" }}>
          <StickyFooter />
        </div>
      ) : (
        <ThirdFooter />
      )}
    </>
  );
}

export default FooterWrapper;
