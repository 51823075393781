import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate } from "../../Helper/Helper";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";

function BlogListing() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [blogsData, setBlogsData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogsData();
  }, []);

  const getBlogsData = async () => {
    const response = await SendPostRequest("blogs/list");
    setBlogsData(response?.data);
  };

  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
      <HeaderWrapper />
      <div class="page-title-wrap typo-white">
        <div
          class="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span class="black-overlay"></span>
          <div class="container">
            <div class="row text-left">
              <div class="col-md-12">
                <div class="page-title-inner">
                  <h1 class="page-title mb-0">Blogs</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section pad-top-80 pad-bottom-80 full-dark">
        <div className="container">
          {blogsData?.length > 0 ? (
            <div className="row">
              <div className="col-md-8">
                <div
                  className="blog-main-wrapper blog-style-6 margin-bottom-30"
                  data-animation="fadeInDown"
                >
                  <div className="blog-inner">
                    <div className="blog-thumb relative mb-4">
                      <img
                        src={blogsData[0]?.image}
                        className="img-fluid"
                        style={{ height: "480px" }}
                        width="750"
                        alt="blog-img"
                      />
                      <div className="blog-details typo-white pt-0">
                        <div className="top-meta mb-1">
                          <ul className="top-meta-list">
                            <li>
                              <div className="post-date">
                                <a href="#">
                                  <i className="ti-calendar theme-color me-1"></i>{" "}
                                  {formatDate(blogsData[0]?.date)}
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-title">
                          <h5 className="margin-bottom-5">
                            <Link
                              to={`/blog/detail/${blogsData[0]?.slug}`}
                              className="blog-name"
                            >
                              {blogsData[0]?.title}
                            </Link>
                          </h5>
                        </div>
                        <div className="blog-link">
                          <Link
                            to={`/blog/detail/${blogsData[0]?.slug}`}
                            className="link font-w-500"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {blogsData?.slice(1)?.map((data, index) => (
                <div
                  key={index}
                  className="col-md-4"
                  data-animation="fadeInDown"
                >
                  <div
                    className="blog-main-wrapper blog-style-5 margin-bottom-30"
                    style={{ height: "480px" }}
                  >
                    <div className="blog-inner">
                      <div className="blog-thumb relative mb-4">
                        <img
                          src={data?.image}
                          className="img-fluid"
                          width="768"
                          height="600"
                          alt="blog-img"
                        />
                      </div>
                      <div className="blog-details pt-0">
                        <div className="top-meta mb-1">
                          <ul className="top-meta-list">
                            <li>
                              <div className="post-date">
                                <a href="#">
                                  <i className="ti-calendar theme-color me-1"></i>
                                  {formatDate(data?.date)}
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-title">
                          <h5 className="margin-bottom-5">
                            <Link
                              to={`/blog/detail/${data?.slug}`}
                              className="blog-name"
                            >
                              {data?.title}
                            </Link>
                          </h5>
                        </div>
                        <div className="post-desc mt-2">
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Velit, fugit?
                          </p>
                          <div className="blog-link">
                            <Link
                              to={`/blog/detail/${data?.slug}`}
                              className="link font-w-500"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="col-12 text-center">
              <h4>No Data Found</h4>
            </div>
          )}

          {/* <div className="row">
            <div className="col-md-4" data-animation="fadeInDown">
              <div className="blog-main-wrapper blog-style-5">
                <div className="blog-inner">
                  <div className="blog-thumb relative mb-4">
                    <img
                      src="/assets/images/blog/blog-grid/blog-grid1.jpg"
                      className="img-fluid"
                      width="768"
                      height="600"
                      alt="blog-img"
                    />
                  </div>
                  <div className="blog-details pt-0">
                    <div className="top-meta mb-1">
                      <ul className="top-meta-list">
                        <li>
                          <div className="post-date">
                            <a href="#">
                              <i className="ti-calendar theme-color me-1"></i>{" "}
                              Oct 21, 2019
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-title">
                      <h5 className="margin-bottom-5">
                        <a
                          href="blog-single-right-sidebar.html"
                          className="blog-name"
                        >
                          A Successful House Build
                        </a>
                      </h5>
                    </div>
                    <div className="post-desc mt-2">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <div className="blog-link">
                        <a
                          target="_blank"
                          href="blog-single-right-sidebar.html"
                          className="link font-w-500"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-animation="fadeInDown">
              <div className="blog-main-wrapper blog-style-5">
                <div className="blog-inner">
                  <div className="blog-thumb relative mb-4">
                    <img
                      src="/assets/images/blog/blog-grid/blog-grid1.jpg"
                      className="img-fluid"
                      width="768"
                      height="600"
                      alt="blog-img"
                    />
                  </div>
                  <div className="blog-details pt-0">
                    <div className="top-meta mb-1">
                      <ul className="top-meta-list">
                        <li>
                          <div className="post-date">
                            <a href="#">
                              <i className="ti-calendar theme-color me-1"></i>{" "}
                              Oct 21, 2019
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-title">
                      <h5 className="margin-bottom-5">
                        <a
                          href="blog-single-right-sidebar.html"
                          className="blog-name"
                        >
                          A Successful House Build
                        </a>
                      </h5>
                    </div>
                    <div className="post-desc mt-2">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <div className="blog-link">
                        <a
                          target="_blank"
                          href="blog-single-right-sidebar.html"
                          className="link font-w-500"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-animation="fadeInDown">
              <div className="blog-main-wrapper blog-style-5">
                <div className="blog-inner">
                  <div className="blog-thumb relative mb-4">
                    <img
                      src="/assets/images/blog/blog-grid/blog-grid1.jpg"
                      className="img-fluid"
                      width="768"
                      height="600"
                      alt="blog-img"
                    />
                  </div>
                  <div className="blog-details pt-0">
                    <div className="top-meta mb-1">
                      <ul className="top-meta-list">
                        <li>
                          <div className="post-date">
                            <a href="#">
                              <i className="ti-calendar theme-color me-1"></i>{" "}
                              Oct 21, 2019
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-title">
                      <h5 className="margin-bottom-5">
                        <a
                          href="blog-single-right-sidebar.html"
                          className="blog-name"
                        >
                          A Successful House Build
                        </a>
                      </h5>
                    </div>
                    <div className="post-desc mt-2">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <div className="blog-link">
                        <a
                          target="_blank"
                          href="blog-single-right-sidebar.html"
                          className="link font-w-500"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <FooterWrapper/>
      </div>
    </div>
  );
}

export default BlogListing;
