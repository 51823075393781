import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderWrapper from "./Header/HeaderWrapper";
import FooterWrapper from "./Footer/FooterWrapper";

function PageNotFound() {
  const sitedata = useSelector((store) => store.global.loginData);
  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
      <HeaderWrapper />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="page-title-inner">
                  <h1 className="page-title mb-0">404 Not Found</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="error-404" className="error-404 pad-top-90">
        <div className="container">
          <div className="row">
            <div className="not-found-wrap">
              <div className="text-center">
                <div className="error-wrap">
                  <div className="error-content">
                    <div className="error-page">
                      <h1 className="pb-4">404</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="error-desc text-center">
                <h2>WE ARE SORRY, PAGE NOT FOUND!</h2>
                <p>
                  THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN REMOVED HAD ITS
                  NAME CHANGED OR IS TEMPORARILY UNAVAILABLE.
                </p>
                <div className="error-btn mt-5">
                  <Link
                    to={"/"}
                    className="btn btn-default text-uppercase b-radius-0"
                    title="Home"
                  >
                    Back to Home Page<i className="fa fa-home ms-3"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterWrapper/>
      </div>
    </div>
  );
}

export default PageNotFound;
