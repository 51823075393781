import React from "react";
import LightDarkHeader from "./LightDarkHeader";
import SecondHeader from "../SecondHeader";
import Header from "../Header";
import { useSelector } from "react-redux";

function HeaderWrapper() {
    const sitedata = useSelector((store) => store.global.loginData);
  return (
    <>
      {sitedata?.theme_header === "version_1" ? (
        <Header />
      ) : sitedata?.theme_header === "version_2" ? (
        <SecondHeader />
      ) : (
        <LightDarkHeader />
      )}
    </>
  );
}

export default HeaderWrapper;
