import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../../http";
import Captcha from "../Captcha/Captcha";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";

function Feedback() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [complaintData, setComplaintData] = useState({
    full_name: null,
    mrn: "",
    email: "",
    contact_number: "",
    complaint_detail: "",
    complaint_attachment_1: null,
    complaint_attachment_2: null,
  });

  function capitalize(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Feedback - ClinTa";
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handlePostData = (e) => {
    let { value, name } = e.target;
    setComplaintData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    let name = e.target.name;
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      setComplaintData((prevData) => ({
        ...prevData,
        [name]: base64String,
      }));
    };
    reader.readAsDataURL(file);
  };

  const [captcha, setCaptcha] = useState("");
  const [input_captcha, setInputCaptcha] = useState("");
  const [check_captcha, setCheckCaptcha] = useState("");
  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!complaintData.full_name) {
      toast.error("Enter Your Name");
      return;
    } else if (!complaintData.contact_number) {
      toast.error("InComplete Form");
      return;
    } else if (
      complaintData.contact_number.length > 14 ||
      complaintData.contact_number.length < 10
    ) {
      toast.error("Invalid Contact Number");
      return;
    } else if (!input_captcha) {
      toast.error("Please Enter Captcha");
      return;
    } else if (captcha !== input_captcha) {
      toast.error("Invalid Captcha");
      return;
    } else if (!complaintData.complaint_detail) {
      toast.error("InComplete Form");
      return;
    }

    if (captcha === input_captcha) {
      setCheckCaptcha("Captcha Verified");
      try {
        setIsSubmitting(true);
        const response = await SendPostRequest("complaints/add", complaintData);
        formRef.current.reset();

        if (response.status === "success") {
          setComplaintData({
            full_name: null,
            mrn: "",
            email: "",
            contact_number: "",
            complaint_detail: "",
            complaint_attachment_1: null,
            complaint_attachment_2: null,
          });
          handleRefresh();
          setInputCaptcha(null);
          setCheckCaptcha(null);

          toast.success("Complaints Submitted Successfully");
        } else {
          let errorMessage = response.message || "An error occurred";
          toast.error(`Error: ${errorMessage}`);
          console.error("Error fetching complaints data:", errorMessage);
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
        console.error("Error fetching complaints data:", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setCheckCaptcha("Captcha failed");
      toast.error("Captcha failed");
      return;
    }
  };

  const textColorClass =
    check_captcha === "Captcha Verified" ? "text-success" : "text-danger";

  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
  };

  const handleRefresh = () => {
    const newCaptcha = generateRandomCaptcha();
    setCaptcha(newCaptcha);
  };

  const generateRandomCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let newCaptcha = "";

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCaptcha += characters.charAt(randomIndex);
    }

    return newCaptcha;
  };

  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
      <HeaderWrapper />
      <div class="page-title-wrap typo-white">
        <div
          class="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span class="black-overlay"></span>
          <div class="container">
            <div class="row text-left">
              <div class="col-md-12">
                <div class="page-title-inner">
                  <h1 class="page-title mb-0">Feedback</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="contact-form" data-animation="fadeInLeft">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
              <div className="row p-4">
                <div className="col-lg-6 mb-3">
                  <label className="form-label">
                    Full Name <span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    className="input-name form-control"
                    name="full_name"
                    value={complaintData.full_name || ""}
                    onChange={handlePostData}
                    placeholder="Enter full name"
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">MRN</label>
                  <input
                    type="text"
                    className="input-name form-control"
                    name="mrn"
                    value={complaintData.mrn || ""}
                    onChange={handlePostData}
                    placeholder="Enter MRN number"
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="input-name form-control"
                    name="email"
                    value={complaintData.email || ""}
                    onChange={handlePostData}
                    placeholder="Enter email"
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">
                    Contact No <span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    className="input-name form-control"
                    name="contact_number"
                    value={complaintData.contact_number || ""}
                    onChange={handlePostData}
                    placeholder="Enter phone number"
                    maxLength={14}
                    minLength={10}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-12 mb-3 appointment_form_capcha">
                  <Captcha
                    captcha={captcha}
                    inputCaptcha={input_captcha}
                    textColorClass={textColorClass}
                    checkCaptcha={check_captcha}
                    handleInputChange={handleInputChange} 
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <label className="form-label">
                    Feedback <span className="asterisk">*</span>
                  </label>
                  <textarea
                    cols="30"
                    rows="5"
                    className="input-name form-control"
                    placeholder="Enter your feedback..."
                    name="complaint_detail"
                    value={complaintData.complaint_detail || ""}
                    onChange={handlePostData}
                  ></textarea>
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">
                    Attachment 1
                  </label>
                  <input
                    type="file"
                    className="input-name form-control drop_cv_pic_upoload_field"
                    placeholder="example@gmail.com"
                    name="complaint_attachment_1"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">
                    Attachment 2
                  </label>
                  <input
                    type="file"
                    className="input-name form-control drop_cv_pic_upoload_field"
                    placeholder="example@gmail.com"
                    name="complaint_attachment_2"
                    // value={Complaints.complaint_attachment_2}
                    file={null}
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-12 mb-3">
                <div className="cs_height_18"></div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-default"
                    style={{ width: "100px" }}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <FooterWrapper/>
      </div>
    </div>
  );
}

export default Feedback;
