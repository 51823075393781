import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import { formatDate } from "../../Helper/Helper";
import Spinner from "../Spinner";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";

function AuthBlogDetail() {
  const sitedata = useSelector((store) => store.global.loginData);
  const { slug } = useParams();
  const [blogsDetail, setBlogsDetail] = useState({});
  const [blogsList, setBlogsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Author Blogs Detail";
    window.scrollTo(0, 0);
    getBlogsDetail();
  }, [slug]);

  const getBlogsDetail = async () => {
    try {
      setLoading(true);
      const response = await SendPostRequest("author/detail", {
        slug: slug,
      });

      if (response.status === "success") {
        setBlogsList(response?.data?.blogs);
        setBlogsDetail(response?.data?.author);
      } else {
        console.warn("No service detail found in the response.");
      }
    } catch (error) {
      console.error("Error fetching services detail:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
<div className="page-wrapper-inner">
<div class="page-wrapper-inner">
<HeaderWrapper />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-left">
              <div className="col-md-12">
                <div className="page-title-inner">
                  <h1 className="page-title mb-0">Author Blogs Detail</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="blog-single" className="blog-single">
        <div className="container">
          <div className="blog-main-wrap blog-list">
            <div className="row">
              <div className="col-lg-8">
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="blog-wrap mb-4 pb-3">
                      <div className="blog-info-wrap">
                        <div className="blog-single-img mb-4 pb-2">
                          <img
                            // src="/assets/images/blog/blog-single.jpg"
                            src={blogsDetail?.image_url}
                            width="1280"
                            height="852"
                            className="img-fluid b-radius-8"
                            alt="blog-img"
                          />
                        </div>
                        <div className="post-meta top-meta margin-bottom-30">
                          <ul className="nav">
                            {/* <li>
                              <a href="#">
                                <span className="author-img">
                                  <img
                                    alt=""
                                    src="/assets/images/icons/author-icon.jpg"
                                    className="avatar avatar-30 photo rounded-circle me-2"
                                    height="30"
                                    width="30"
                                  />
                                </span>
                                Jack
                              </a>
                            </li> */}
                            <li className="nav-item">
                              <div className="post-category">
                                <span className="ti-folder me-2 theme-color"></span>
                                <Link>{blogsDetail?.title}</Link>
                              </div>
                            </li>
                            <li className="nav-item">
                              <div className="post-date">
                                <span className="ti-time me-2 theme-color"></span>
                                <a>{formatDate(blogsDetail?.date)}</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-content pb-4 mb-2">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: blogsDetail?.content,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="col-lg-4 ps-5 px-sm-15">
                <div className="sidebar right-sidebar">
                  <div className="widget category-widget">
                    <div className="widget-title">
                      <h3 className="title">Other Blogs</h3>
                    </div>
                    <ul className="category-list">
                      {blogsList && blogsList.length > 0 ? (
                        blogsList.map((list, index) => (
                          <li key={index}>
                            <Link to={`/blog/detail/${list?.slug}`}>
                              {list?.title}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li>No other blogs found</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterWrapper />
      </div>
    </div>
  );
}

export default AuthBlogDetail;
