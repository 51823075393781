import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";

function FacilitiesListing() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getFacilitiesData();
  }, []);

  const getFacilitiesData = async () => {
    const response = await SendPostRequest("facilities");
    setIsLoading(false);
    setFacilitiesData(response?.data);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const [show, setShow] = useState(false);
  const [facilitiesIndex, setFacilitiesIndex] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setFacilitiesIndex(index);
    setShow(true);
  };

  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
      <HeaderWrapper />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-left">
              <div className="col-md-12">
                <div className="page-title-inner">
                  <h1 className="page-title mb-0">Our Facilities</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="offset-md-2 col-md-8">
            <div className="title-wrap text-center">
              <div className="section-title margin-bottom-60">
                <h2 className="section-title mb-0 text-uppercase">
                  Provides Our{" "}
                  <span className="theme-color">Best Facilities</span>
                </h2>
                <span className="section-border-bottom center"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {facilitiesData.length === 0 ? (
            <div
              style={{ marginBottom: "100px" }}
              className="d-flex align-items-center justify-content-center"
            >
              {isLoading ? <Spinner /> : <h4>No Facilities Found</h4>}
            </div>
          ) : (
            facilitiesData?.map((data, index) => (
              <div key={index} className="col-lg-4 pad-top-md-30 mb-5">
                <div className="feature-box-wrap f-box-style-1 relative">
                  <div className="feature-box-details text-center">
                    <div
                      className="feature-img"
                      style={{
                        backgroundImage: `url(${
                          data?.facility_image || "/assets/images/default.jpg"
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                        width: "100%",
                        height: "300px",
                      }}
                    ></div>
                    <div className="feature-content mt-4 mb-3">
                      <div className="feature-title relative margin-bottom-5">
                        <h5>{data?.facility_title}</h5>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.facility_description
                            ? truncateText(data.facility_description, 100)
                            : "",
                        }}
                      />
                      <div className="button">
                        <Link
                          className="btn-link"
                          onClick={() => handleShow(index)}
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="review-modal-title">
            {facilitiesData[facilitiesIndex]?.facility_title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{
              __html: facilitiesData[facilitiesIndex]?.facility_description,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <FooterWrapper/>
      </div>
    </div>
  );
}

export default FacilitiesListing;
