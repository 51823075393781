import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { capitalize } from "@mui/material";
import { SendPostRequest } from "../http";
import Captcha from "./Captcha/Captcha";
import HeaderWrapper from "./Header/HeaderWrapper";
import FooterWrapper from "./Footer/FooterWrapper";

function SignUp() {
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Signup - ClinTa";
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SendPostRequest("hospitals/department");
        if (res.status === "success" && res?.data && res?.data?.Departments) {
          const formattedOptions = res?.data?.Departments.map((department) => ({
            value: department,
            label: department,
          }));
        } else {
          console.error("Invalid or empty response from the API");
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchData();
  }, []);

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const [captcha, setCaptcha] = useState("");
  const [input_captcha, setInputCaptcha] = useState("");
  const [check_captcha, setCheckCaptcha] = useState("");

  const navigate = useNavigate();

  const textColorClass =
    check_captcha === "Captcha Verified" ? "text-success" : "text-danger";
  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
  };

  const handleRefresh = () => {
    const newCaptcha = generateRandomCaptcha();
    setCaptcha(newCaptcha);
  };

  const generateRandomCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let newCaptcha = "";

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCaptcha += characters.charAt(randomIndex);
    }

    return newCaptcha;
  };

  const [inputValues, setInputValues] = useState({});
  const [gender, setGender] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleGenderChange = (selectedOption) => {
    setGender(selectedOption.value);
  };

  const handleAllInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValues?.full_name) {
      toast.error("Please enter your name");
      return;
    } else if (!gender) {
      toast.error("Please select your gender");
      return;
    } else if (!inputValues?.email) {
      toast.error("Please enter your email");
      return;
    } else if (
      !inputValues.email.includes(".") ||
      !inputValues.email.includes("@")
    ) {
      toast.error("Invalid email");
      return;
    } else if (!inputValues?.contact_number) {
      toast.error("Please enter your contact number ");
      return;
    } else if (inputValues.contact_number?.length !== 11) {
      toast.error("Invalid Number ");
      return;
    } else if (isNaN(inputValues?.contact_number)) {
      toast.error("Invalid number");
      return;
    } else if (!inputValues?.age) {
      toast.error("Please enter your age");
      return;
    } else if (isNaN(inputValues.age)) {
      toast.error("Invalid age");
      return;
    } else if (!input_captcha) {
      toast.error("You haven't enter captcha");
      return;
    }

    if (captcha === input_captcha) {
      setLoading(true);
      setCheckCaptcha("Captcha Verified");

      try {
        const myallInputs = {
          ...inputValues,
          gender,
        };
        const response = await SendPostRequest("auth/signup", myallInputs);
        setInputValues({
          full_name: "",
          email: "",
          contact_number: "",
          age: "",
        });
        setLoading(false);
        setGender({ value: "" });
        handleRefresh();
        setInputCaptcha("");
        setCheckCaptcha("");
        // setInputCaptcha(null)
        setInputCaptcha([]);

        if (response.status === "success") {
          toast.success(response.message);
          navigate("/login");
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error("Something Went Wrong");
      }
    } else {
      setCheckCaptcha("Captcha failed");
      toast.error("Captcha verification failed");
    }
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  let themeColor;
  if (sitedata) {
    themeColor = sitedata?.theme_color?.split(".css").join("");
  } else {
  }
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
        <HeaderWrapper />
        <div className="page-title-wrap typo-white">
          <div
            className="page-title-wrap-inner section-bg-img"
            data-bg="/assets/images/bg/page-title-bg.jpg"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            <span className="black-overlay"></span>
            <div className="container">
              <div className="row text-center">
                <div className="col-md-12">
                  <div className="page-title-inner">
                    <div id="breadcrumb" className="breadcrumb mb-1 mb-lg-2">
                      <a href="index.html" className="theme-color">
                        Home
                      </a>
                      <span className="current">Pages</span>
                    </div>
                    <h1 className="page-title mb-0">Registration</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper pad-none">
          <div className="content-inner">
            <section id="section-contact" className="pad-top-5 pt-5 pb-2">
              <div className="container">
                <div className="row">
                  <div className="offset-md-2 col-md-8">
                    <div className="title-wrap text-center">
                      <div className="section-title">
                        <h2 className="title mb-0 text-uppercase">
                          Register Your Account
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <div className="contact-form-4">
                      <h3 className="title mb-3">Register Now</h3>
                      <div className="contact-form-wrap">
                        <form
                          id="contact-form"
                          className="contact-form bv-form"
                          action="inc/function.php"
                          enctype="multipart/form-data"
                          novalidate="novalidate"
                        >
                          <button
                            type="submit"
                            className="bv-hidden-submit"
                            style={{
                              display: "none",
                              width: "0px",
                              height: "0px",
                            }}
                          ></button>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 form-group has-feedback mb-4">
                              <input
                                id="name"
                                className="form-control"
                                placeholder="Enter Full Name *"
                                name="full_name"
                                value={inputValues.full_name}
                                onChange={handleAllInputChange}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 form-group has-feedback mb-4">
                              <Select
                                className="theme-2-gender-select"
                                name="gender"
                                value={gender?.value}
                                options={genderOptions}
                                onChange={handleGenderChange}
                                placeholder="Select Gender *"
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 form-group has-feedback mb-4">
                              <input
                                className="form-control"
                                type="email"
                                placeholder="Enter Your Email *"
                                name="email"
                                value={inputValues.email}
                                onChange={handleAllInputChange}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 form-group has-feedback mb-4">
                              <input
                                type="number"
                                className="form-control"
                                value={inputValues.contact_number}
                                placeholder="Enter Contact Number *"
                                name="contact_number"
                                onChange={handleAllInputChange}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 form-group has-feedback mb-4">
                              <input
                                id="name"
                                className="form-control"
                                placeholder="Enter Your Age *"
                                name="age"
                                value={inputValues.age}
                                onChange={handleAllInputChange}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 form-group has-feedback mb-4">
                              <Captcha
                                captcha={captcha}
                                inputCaptcha={input_captcha}
                                textColorClass={textColorClass}
                                checkCaptcha={check_captcha}
                                handleInputChange={handleInputChange}
                              />
                            </div>

                            <div className="col-md-12">
                              <button
                                onClick={handleSubmit}
                                disabled={loading}
                                className="btn btn-default mt-0 theme-btn"
                              >
                                <span>
                                  {loading ? "Please wait" : "Sing up"}
                                </span>
                              </button>
                            </div>
                          </div>
                          <span className="clearfix"></span>
                        </form>
                        <div className="captcha-parent">
                          <div
                            className="g-recaptcha"
                            data-sitekey="6LcuIvEcAAAAAFnQUTIoRRn6Gvc54vbWAf0GSEdP"
                            data-callback="verifyRecaptchaCallback"
                          ></div>
                        </div>
                        <p id="contact-status-msg" className="hide"></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 align-self-center">
                    <div className="contact-form-4">
                      <h3 className="title mb-3">Sign Up Query</h3>
                      <img
                        src="/assets/img/appointments/appointment_img.jpg"
                        alt="Appointment"
                        className="cs_radius_2 mb-3"
                        style={{
                          width: "100%",
                          height: "280px",
                          borderRadius: "5px",
                        }}
                      />
                      <ul className="row cs_contact_info cs_style_1 cs_mp0">
                        <li className="col-lg-6 col-12">
                          <h3 className="cs_fs_24 cs_semibold mb-0">Phone</h3>
                          <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                            <a href={`tel:${sitedata?.site_contact}`}>
                              {sitedata?.site_contact}
                            </a>
                          </p>
                        </li>
                        <li className="col-lg-6 col-12">
                          <h3 className="cs_fs_24 cs_semibold mb-0">
                            Email Us
                          </h3>
                          <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                            <a href={`mailto:${sitedata?.site_email}`}>
                              {sitedata?.site_email}
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <FooterWrapper />
      </div>
    </div>
  );
}

export default SignUp;
