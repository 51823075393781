import React from "react";
import ScrollToTop from "../Theme2DashboardTabs/ScrollToTop";
import { useSelector } from "react-redux";

function StickyFooter() {
  const sitedata = useSelector((store) => store.global.loginData);
  return (
    <footer id="footer" className="footer bg-grey2 footer-1 footer-hidden">
      <div className="footer-widgets">
        <div className="footer-middle-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 widget text-widget">
                <div className="widget-title">
                  <h3 className="title text-uppercase">About Us</h3>
                </div>
                <div className="widget-text margin-bottom-15">
                  <p>
                    Mist is a mobile friendly business template specially
                    designed.
                  </p>
                </div>
                <nav>
                  <ul className="contact-info">
                    <li>
                      <i
                        aria-hidden="true"
                        className="ti-location-pin theme-color"
                      ></i>{" "}
                      <span>
                        {" "}
                        No. 12, Ribon Building, Walse street, Australia.
                      </span>
                    </li>
                    <li>
                      <i
                        aria-hidden="true"
                        className="ti-email theme-color"
                      ></i>{" "}
                      <a href={`mailto:${sitedata?.site_email}`}>
                        {sitedata?.site_email}
                      </a>
                    </li>
                    <li>
                      <i
                        aria-hidden="true"
                        className="ti-headphone theme-color"
                      ></i>{" "}
                      <a href={`tel:${sitedata?.site_contact}`}>
                        {sitedata?.site_contact}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-3 widget text-widget">
                <div className="widget-title">
                  <h3 className="title text-uppercase">Blog Roll</h3>
                </div>
                <div className="menu-quick-links">
                  <ul className="menu">
                    <li className="menu-item">
                      <a href="#">Complete Documentations</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Additional Plugins & Modules</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Child Themes</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Support Forums</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Useful Blog</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 widget recent-posts">
                <div className="widget-title">
                  <h3 className="title text-uppercase">Latest News</h3>
                </div>
                <nav>
                  <ul className="footer-list-posts">
                    <li>
                      <div className="side-image">
                        <a href="blog-single-right-sidebar.html">
                          <img
                            width="80"
                            height="80"
                            src="/assets/images/blog/thumb/blog-thumb1.jpg"
                            className="img-responsive wp-post-image"
                            alt="Blog"
                          />
                        </a>
                      </div>
                      <div className="side-item-text">
                        <a href="blog-single-right-sidebar.html">
                          Sample Audio Post
                        </a>
                        <span className="post-date d-block">Oct 21, 2019</span>
                      </div>
                    </li>
                    <li>
                      <div className="side-image">
                        <a href="blog-single-right-sidebar.html">
                          <img
                            width="80"
                            height="80"
                            src="/assets/images/blog/thumb/blog-thumb2.jpg"
                            className="img-responsive wp-post-image"
                            alt="Blog"
                          />
                        </a>
                      </div>
                      <div className="side-item-text">
                        <a href="blog-single-right-sidebar.html">
                          Sample Video Post
                        </a>
                        <span className="post-date d-block">Oct 21, 2019</span>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-3 widget contact-info-widget">
                <div className="widget-title">
                  <h3 className="title text-uppercase">Newsletter Signup</h3>
                </div>
                <div className="footer-counter counter-value text-uppercase mt-3">
                  <h4>
                    <span className="counter-suffix">Total Projects : </span>
                    <span className="counter-up" data-count="3550">
                      0
                    </span>
                  </h4>
                </div>
                <div className="footer-counter counter-value text-uppercase">
                  <h4>
                    <span className="counter-suffix">Happy Clients : </span>
                    <span className="counter-up" data-count="2550">
                      0
                    </span>
                  </h4>
                </div>
                <div className="social-icons mt-3 mb-3">
                  {sitedata?.site_extra?.facebook_link && (
                    <a
                      href={sitedata?.site_extra?.facebook_link}
                      className="social-fb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  )}
                  {sitedata?.site_extra?.twitter_link && (
                    <a
                      href={sitedata?.site_extra?.twitter_link}
                      className="social-twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="ti-twitter"></span>
                    </a>
                  )}
                  {sitedata?.site_extra?.instagram_link && (
                    <a
                      href={sitedata?.site_extra?.instagram_link}
                      className="social-instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  )}
                  {sitedata?.site_extra?.linkedin_link && (
                    <a
                      href={sitedata?.site_extra?.linkedin_link}
                      className="social-instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="ti-linkedin"></span>
                    </a>
                  )}
                </div>
                <div className="mailchimp-widget-wrap">
                  <form
                    id="subscribe-form-1"
                    className="subscribe-form"
                    action="inc/function.php"
                  >
                    <div className="input-group add-on">
                      <input
                        type="text"
                        className="form-control"
                        name="mcemail"
                        autocomplete="off"
                        id="mcemail-1"
                        placeholder="Email Address"
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-default subscribe-btn"
                          type="submit"
                        >
                          <i className="ti-email"></i>
                        </button>
                      </div>
                    </div>
                    <p className="subscribe-status-msg hide"></p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright bg-grey">
        <div className="footer-bottom-wrap pad-tb-20">
          <div className="container">
            <div className="row">
              <div className="col-md-12 copyright-section">
                <ul className="footer-bottom-items pull-left">
                  <li class="nav-item">
                    <div class="nav-item-inner copyright">
                      Copyrights © 2023 <a>ClinTa</a>. Powered by{" "}
                      <a>D-Right Technologies.</a>
                    </div>
                  </li>
                  <ul className="footer-bottom-items footer-menu">
                    <li className="nav-item">
                      <a href="#">Terms of Use</a>
                    </li>
                    <li className="nav-item">
                      <a href="#">Privacy Policy</a>
                    </li>
                  </ul>
                </ul>
                <ul className="footer-bottom-items pull-right">
                  <ScrollToTop />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default StickyFooter;
